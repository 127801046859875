import React from 'react'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export class Plurality extends React.Component
{
    constructor(props) {
        super(props)
        const income = 60000
        this.state = {
            candidates: this.props.candidates
        }
    }

    render() {
        // brackets is an array of [upper limit, rate (in percent)]
        const {candidates} = this.props
        let rows = []
        if (candidates) {
            candidates.forEach(candidate => {
                rows.push(
                    <tr key={index}>
                        <td>
                            {candidate}
                        </td>
                    </tr>
                )
            })
        }
        return <div>
            <table>
                {rows}
            </table>
            </div>
    }
}
