import React from 'react'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export class TaxBrackets extends React.Component
{
    constructor(props) {
        super(props)
        const income = 60000
        this.state = {
            income: income,
            donation: this.donation(this.props.brackets, income)
        }
        this.handleIncome = this.handleIncome.bind(this);
    }

    donation(brackets, income) {
        let donation = 0
        if (!brackets)
            return 0
        brackets.forEach((bracket, index) => {
            let low = 0
            if (index > 0) {
                low = brackets[index-1][0]
            }
            const [high, rate] = bracket
            let bracketIncome = Math.max(Math.min(income - low, high - low), 0)
            donation += bracketIncome * rate / 100
        })
        return Math.round(donation)
    }

    handleIncome(event) {
        const {brackets} = this.props
        const income = event.target.value
        this.setState({
            income: income,
            donation: this.donation(brackets, income)
        })
    }

    render() {
        // brackets is an array of [upper limit, rate (in percent)]
        const {brackets} = this.props
        let rows = []
        if (brackets) {
            brackets.forEach((bracket, index) => {
                let previous = 0
                if (index > 0) {
                    previous = brackets[index - 1][0]
                }
                rows.push(
                    <tr key={index}>
                        <td align="right">${previous.toLocaleString()}</td>
                        <td>&mdash;</td>
                        <td align="right">${bracket[0].toLocaleString()}</td>
                        <td align="right">{bracket[1].toLocaleString()}%</td>
                    </tr>
                )
            })
        }
        const fraction = 100 * this.state.donation / this.state.income
        return <div>
            <TextField
                label="Income"
                id="income-textfield"
                value={this.state.income}
                onChange={this.handleIncome}
            />
            Donation: ${this.state.donation.toLocaleString()}
            {" "}
            ({fraction.toLocaleString()}%)
            <table>
                <tr><th colspan="3">Of Income</th><th>Donate</th></tr>
                {rows}
            </table>
            </div>
    }
}

export function MultipleBrackets(props)
{
    const {brackets} = props
    /* brackets: tax code -> filing status -> bracket list */
    let map = new Map()
    brackets.forEach(([k, v]) => map.set(k, new Map(v)))
    const taxCodeList = Array.from(map.keys())

    const [taxCode, setTaxCode] = React.useState(taxCodeList[0])
    const filingStatusList = Array.from(map.get(taxCode).keys())

    const [filingStatus, setFilingStatus] = React.useState(filingStatusList[0])
    if (!filingStatusList.includes(filingStatus)) {
        setFilingStatus(filingStatusList[0])
    }

    const handleTaxCode = (event) => {
        setTaxCode(event.target.value)
    }

    const handleFilingStatus = (event) => {
        setFilingStatus(event.target.value)
    }

    let filingStatusInput
    if (filingStatusList.length > 1) {
        filingStatusInput = 
            <FormControl>
                <InputLabel id="filing-status-label">Filing Status</InputLabel>
                <Select
                    labelId="filing-status-label"
                    id="filing-status-select"
                    value={filingStatus}
                    onChange={handleFilingStatus}
                >
                    {filingStatusList.map(k => <MenuItem value={k}>{k}</MenuItem>)}
                </Select>
            </FormControl>;
    }

    return <div>
        <FormControl>
            <InputLabel id="tax-code-label">Tax Code</InputLabel>
            <Select
                labelId="tax-code-label"
                id="tax-code-select"
                value={taxCode}
                onChange={handleTaxCode}
            >
                {taxCodeList.map(k => <MenuItem value={k}>{k}</MenuItem>)}
            </Select>
        </FormControl>

        {filingStatusInput}

        <TaxBrackets brackets={map.get(taxCode).get(filingStatus)} />
    </div>
}
